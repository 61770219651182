// Variable overrides
$primary: #00a4de;
$secondary: #13216a;
$success: #007eb2;
$info: #1947ba;
$warning: #5f28b2;
$danger: #e55353 ;
$gray: #434244;

$white:  #ffffff;
$black:  #000000;

$pink:  #e83e8c;

$gray-base: $gray;
$gray-100: lighten($gray, 61%);
$gray-200: lighten($gray, 56%);
$gray-300: lighten($gray, 49%);
$gray-400: lighten($gray, 42%);
$gray-500: lighten($gray, 35%);
$gray-600: lighten($gray, 28%);
$gray-700: lighten($gray, 21%);
$gray-800: lighten($gray, 14%);
$gray-900: lighten($gray, 7%);

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
    (
      "100":  $gray-100,
      "200":  $gray-200,
      "300":  $gray-300,
      "400":  $gray-400,
      "500":  $gray-500,
      "600":  $gray-600,
      "700":  $gray-700,
      "800":  $gray-800,
      "900":  $gray-900
    ),
    $grays
);

$primary-base:  $primary;
$primary-100: lighten($primary, 90%);
$primary-200: lighten($primary, 80%);
$primary-300: lighten($primary, 70%);
$primary-400: lighten($primary, 60%);
$primary-500: lighten($primary, 50%);
$primary-600: lighten($primary, 40%);
$primary-700: lighten($primary, 30%);
$primary-800: lighten($primary, 20%);
$primary-900: lighten($primary, 10%);
$primary-dark: darken($primary, 50%);
$primary-50: lighten($primary, 50%);
$primary-25: lighten($primary, 25%);

$secondary-base: $secondary;
$secondary-100: lighten($secondary, 90);
$secondary-200: lighten($secondary, 80);
$secondary-300: lighten($secondary, 70);
$secondary-400: lighten($secondary, 60);
$secondary-500: lighten($secondary, 50);
$secondary-600: lighten($secondary, 40);
$secondary-700: lighten($secondary, 30);
$secondary-800: lighten($secondary, 20);
$secondary-900: lighten($secondary, 10);

$secondary-dark: darken($secondary, 50);
$secondary-50: lighten($secondary, 50);
$secondary-25: lighten($secondary, 25);

$danger-dark: darken($danger, 50);
$danger-50: lighten($danger, 50);
$danger-25: lighten($danger, 25);

$info-dark: darken($info, 50);
$info-50: lighten($info, 50);
$info-25: lighten($info, 25);

$success-dark: darken($success, 50);
$success-50: lighten($success, 50);
$success-25: lighten($success, 25);

$warning-dark: darken($warning, 50);
$warning-50: lighten($warning, 50);
$warning-25: lighten($warning, 25);

$light:  $gray-100;
$dark:   $gray-800;


// scss-docs-start theme-colors-map
$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
      "primary":    $primary,
      "secondary":  $secondary,
      "success":    $success,
      "info":       $info,
      "warning":    $warning,
      "danger":     $danger,
      "light":      $light,
      "dark":       $dark
    ),
    $theme-colors
);

